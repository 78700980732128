/**
This file contains all the colors that are not theme color (green or blue).
In the interest of color separation all color specific css should be added to either this file
or the themes.scss file.
No other css information should be added here.
**/

.sq-text-warning, .sq-icon-warning {
  color: $sq-text-warning-color;
}

.sq-text-success, .sq-icon-success {
  color: $sq-success-color;
}

.sq-text-danger, .sq-icon-danger {
  color: $sq-danger-color !important;
}

.sq-alert-danger {
  color: $sq-danger-color !important;
  background-color: $sq-bg-danger-color;
}

.sq-alert-warning {
  color: $sq-text-dark-warning-color !important;
  background-color: $sq-bg-warning-color;
  border-color: $sq-bg-warning-border-color;
}

.sq-dark-gray {
  color: $sq-dark-gray;
}

.sq-fairly-dark-gray, .sq-icon-dark-gray {
  color: $sq-fairly-dark-gray;
}

.sq-fairly-dark-gray-always {
  &, &:hover, &:focus {
    color: $sq-fairly-dark-gray;
  }
}

.sq-darkish-gray, .sq-icon-darkish-gray {
  color: $sq-darkish-gray;
}

.sq-link-color-analysis {
  color: $sq-link-color-analysis;
}

.link-black {
  color: $black !important;
}

.sq-force-text-gray {
  color: $sq-text-color !important;
}

.sq-status-error {
  color: $sq-status-error-color !important;
}

.sq-status-progress {
  color: $sq-status-progress-color !important;
}

.sq-status-warning {
  color: $sq-status-warning-color !important;
}

.sq-status-good {
  color: $sq-status-good-color !important;
}

.sq-btn-outline {
  color: $sq-text-color;
  border-color: $sq-disabled-gray;
  background-color: $white;

  &:hover {
    background-color: $sq-light-gray;
  }

  &:focus {
    border-color: darken($color: $sq-darkest-gray, $amount: 20);
  }

  &:active {
    background-color: $sq-dark-gray;
  }

  &:active:focus, &:active:hover {
    background-color: $sq-disabled-gray;
  }
}

.splashScreenLight {
  background-color: $sq-light-gray;
}

.fa-panel-color {
  color: $sq-light-gray;
}

#wsScrollBtns {
  border-bottom: 1px solid rgba(196, 211, 214, 0.07);

  a[disabled] {
    color: $sq-disabled-gray;
  }
}

.bg-warning .tooltip-inner {
  @extend .bg-warning;
  color: $sq-text-color;
}

.sq-bg-success {
  background-color: $sq-bg-success-color;
}

.sq-bg-danger {
  background-color: $sq-bg-danger-color;
}

sq-formula {
  .CodeMirror {
    background-color: $sq-light-gray;
  }
}

ui-codemirror {

  // These are some of the styles applied to the .well class
  .CodeMirror {
    border: 1px solid $sq-dark-gray;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .05));
  }
}

.CodeMirror-lint-tooltip {
  background-color: $white;
  border: 1px solid $sq-disabled-gray;
  @include box-shadow(0 5px 10px rgba(0, 0, 0, .2));
}

@media screen {
  hr.fr-page-break:after {
    background: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  }
}

div.reportBackupPreview {
  background-color: $sq-light-gray;
}

.anchorButtonBorder {
  border: 1px solid $sq-light-gray;
}

.sq-btn-default {
  @extend .btn-default;

  &, &:active, &:focus, &:hover, &:active:focus, &:active:hover {
    background-color: $white;
  }
}

.sq-btn-warning {
  @extend .btn-warning;
}

.reportBackups {
  div[uib-accordion-group] {
    border: 1px solid $sq-darkest-gray;

    .card-header {
      border-bottom: 1px solid $sq-darkest-gray !important;
    }

    .card-body {
      .versionEntry {
        border-bottom: 1px solid $sq-darkest-gray;

        .clickPreviewPrompt {
          color: $black;
        }

        &:hover {
          background-color: $white;
        }
      }
    }
  }
}

.selected-template {
  background-color: $backgroundcolor-highlight;

  &:hover {
    background-color: $backgroundcolor-highlight-hover;
  }
}

.report-content-modal, .items-from-worksheet-modal {

  .report-worksheets-list {
    border: 1px solid $sq-disabled-gray;
    background-color: $sq-light-gray;

    .active-worksheet {
      background-color: $backgroundcolor-highlight;

      &:hover {
        background-color: $backgroundcolor-highlight-hover;
      }
    }
  }

  .worksheetContainer {
    padding: 5px;
    border: 1px solid $sq-disabled-gray;
    border-radius: 5px;
    background-color: $white;

    img {
      border: 1px solid $sq-disabled-gray;
      min-height: 80px;
    }
  }

  .reportContentUrl {
    textarea {
      border: 1px solid $sq-disabled-gray;
    }
  }

  .reportContentProperties {
    @extend .flexFillOverflow;

    .image, .placeholder {
      border: 1px solid $sq-disabled-gray;
    }

    .properties {
      border-left: 1px solid $sq-disabled-gray;
      border-right: 1px solid $sq-disabled-gray;
      border-bottom: 1px solid $sq-disabled-gray;
    }

    .settings {
      border-top: 1px solid $sq-disabled-gray;
      border-right: 1px solid $sq-disabled-gray;
      border-bottom: 1px solid $sq-disabled-gray;
    }
  }
}

.bulkEditModalBody {
  border: 1px solid $sq-disabled-gray;
}

.borderGray {
  border: 1px solid $sq-disabled-gray !important;
}

.bulkEditProperties {
  @extend .flexFillOverflow;
  border-left: 1px solid $sq-disabled-gray;
}

.reportConfig [disabled] {
  color: gray;
}

.reportConfigDateRangeHeader {
  background-color: $sq-lightest-gray;
}

.seeqContentDropdown {
  & > div {
    color: $sq-side-panel-text-color !important;
  }

  &:hover {
    background-color: $sq-dark-gray !important;
  }
}

.borderColorWhite {
  border-color: $white !important;
}

.error-border {
  // Copy of the bootstraps has-error effect on fields see the form-control-validation mixin in bootstrap source
  border-color: $sq-danger-color !important;
  box-shadow: inset 0 1px 1px rgba($black, 0.075);

  &:focus {
    border-color: darken($sq-danger-color, 10%) !important;
    box-shadow: inset 0 1px 1px rgba($black, .075), 0 0 6px lighten($sq-danger-color, 20%) !important;
  }
}

.disabled {
  &, &:hover, &:active, &:focus {
    color: $sq-disabled-gray;
  }
}

.hoverHighlightPanel:hover {
  background-color: $sq-light-gray;
}

.backgroundColorWhite {
  background-color: $white;
}

.backgroundColorOffWhite {
  background-color: $sq-darker-gray;
}

.dividerBorderTop {
  border-top: solid 1px $sq-darkest-gray;
}

.dividerBorderBottom {
  border-bottom: solid 1px $sq-darkest-gray;
}

.resizablePanel {
  .showResizeablePanel {
    background-color: $white;
    border: solid 1px $sq-darker-gray;
  }

  .handle-right {
    &:hover, &:active, &:focus {
      background-color: $sq-light-gray;
    }
  }

  .handle-bottom {
    &:hover, &:active, &:focus {
      background-color: $sq-light-gray;
    }
  }
}

.toolOptions {
  border: 1px solid $sq-dark-gray;

  .divider {
    border-top: 1px solid $sq-dark-gray;
  }
}

sq-treemap-chart {
  .node {
    background-image: linear-gradient(to left top, rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));

    &.uncertain {
      background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255, 255, 255, .5) 5px, rgba(255, 255, 255, .5) 10px),
      linear-gradient(to bottom, rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));
    }

    &.uncertain:hover {
      background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255, 255, 255, .5) 5px, rgba(255, 255, 255, .5) 10px);
    }

    .btn {
      background-color: $sq-light-gray;

      &:hover {
        background-color: $white;
      }
    }
  }
}

#detailsPanelContent {
  .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    background-color: $sq-darker-gray;
  }
}

.selectedTableRow {
  background-color: $backgroundcolor-table-highlight-green !important;
}

sq-license-expiration-warning {
  .warning {
    background-color: rgba($backgroundcolor-hover, 0.90);
  }

  a {
    color: $sq-text-color;
  }
}

.license-management {
  background-color: $sq-darker-gray;
  color: $sq-text-color;

  .grayTxt {
    color: $sq-side-panel-text-color;
  }
}

.autoUpdatePopup {
  .header {
    border-bottom: 1px solid $sq-darker-gray;
    background: $sq-light-gray;
  }

  .errorMsg {
    color: $sq-danger-color !important;
  }
}

.toolsSearchBar {
  border-bottom: 1px solid lighten($sq-darkest-gray, 20%);
}

.tableHighlightBackground {
  background-color: $backgroundcolor-table-highlight !important;
}

.viewing-badge {
  color: $sq-dark-gray;
}

.file-drop-zone {
  background-color: $sq-light-gray;
  border: dashed 2px $sq-disabled-gray;
}

.file-drop-zone-uploaded {
  background-color: $sq-light-gray;
  border: solid 1px $sq-disabled-gray;
  border-radius: 4px;
}

.file-drop-zone-error {
  border: dashed 2px $sq-danger-color !important;
}

.unstyled-input {
  color: $sq-fairly-dark-gray;

  &:hover, &:active {
    box-shadow: none !important;
  }
}

.colorPickerSwatch {
  &.inactive {
    background-color: $sq-disabled-gray;
  }
}

.administration {
  .leftBorder {
    border-left: 1px solid $sq-dark-gray;
  }
}

#formulaContainer {
  .card {
    border: 1px solid $sq-darkest-gray !important;
  }


  .rg-right {
    &:hover {
      background-color: $sq-overlay-gray;
    }
  }

  .rg-bottom {
    &:hover {
      background-color: $sq-overlay-gray;
    }
  }
}

.sq-chart {
  .highcharts-cursor-anchor, .highcharts-cursor-y-label, .highcharts-hover-y-label {
    text {
      fill: $white;
    }
  }

  .highcharts-cursor-x-label {
    text {
      fill: $sq-side-panel-text-color;
    }

    stroke: $sq-side-panel-text-color;
  }

  .highcharts-hover-x-label {
    stroke: $sq-side-panel-text-color;
  }
}

select:required:invalid, .select-empty {
  color: $sq-darkest-gray;
}

option {
  color: $black;
}

.worksheetViewSelector {
  .icon {
    color: $white;
  }

  .fa-chevron-down {
    color: $sq-dark-gray;
  }
}

.dl-striped {
  > div:nth-of-type(odd) > dt, > div:nth-of-type(odd) > dd {
    background-color: $sq-lightest-gray;
  }
}

.dl-striped-reversed {
  > div:nth-of-type(even) > dt, > div:nth-of-type(even) > dd {
    background-color: $sq-table-highlight;
  }
}

.fa.sq-text-warning {
  color: $sq-text-warning-color !important;
}

.bg-warning {
  background-color: $backgroundcolor-warn1 !important;
}

.bg-warningProgressBar {
  background-color: $sq-status-warning-color
}

.sq-text-darkest-gray {
  color: $sq-darkest-gray;
}

// Improve the look of blue icons when they are hovered over in the dropdown
.dropdown-menu > li {
  &:hover .sq-text-primary, a:focus .sq-text-primary {
    color: $white !important;
  }
}

.fa-panel-color {
  color: $sq-light-gray;
}

.darkGreyBorder {
  border: 1px solid $sq-darkish-gray;
}

.lightGreyBorder {
  border: 1px solid $sq-disabled-gray;
}

.lightGreyBorderLeft {
  border-left: 1px solid $sq-disabled-gray;
}

.lightGreyBorderRight {
  border-right: 1px solid $sq-disabled-gray;
}

.lightGreyBorderTop {
  border-top: 1px solid $sq-disabled-gray;
}

.lightGreyBorderBottom {
  border-bottom: 1px solid $sq-disabled-gray;
}

.toggleVisibilityBar {
  background: $sq-light-gray;
}

.white {
  color: $white !important;
}

.sq-bg-light-gray {
  background-color: $sq-light-gray;
}

.sq-bg-dark-gray {
  background-color: $sq-dark-gray;
}

.danger-tooltip {
  .tooltip-inner {
    color: $white;
    background-color: $sq-danger-color !important;
    @extend .nowrap;
  }

  &.tooltip.top .tooltip-arrow {
    border-top-color: $sq-danger-color !important;
  }

  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $sq-danger-color !important;
  }

  &.tooltip.left .tooltip-arrow {
    border-left-color: $sq-danger-color !important;
  }

  &.tooltip.right .tooltip-arrow {
    border-right-color: $sq-danger-color !important;
  }
}

/* Specify styling for tooltips that specify tooltip-class="warningTooltip" */
.tooltip.warningTooltip {
  .tooltip-inner {
    color: $black;
    background-color: $sq-text-warning-color;
  }
}

// Can get this from bootstrap after we upgrade to v4.0
.alert-secondary {
  color: $sq-text-color;
  background-color: $sq-overlay-gray;
  border-color: $sq-disabled-gray;
}

.alert-secondary-hover:hover {
  background-color: darken($sq-overlay-gray, 5%);
}

.noBackground {
  background-color: transparent !important;
}

.text-inactive {
  color: $sq-side-panel-text-color;
}

.gray-text, .sq-icon-text {
  color: $sq-text-color;
}

.text-color-default {
  color: $text-color;
}

.text-white, .sq-icon-white {
  color: $white;
}

.sq-icon-gray {
  color: $sq-disabled-gray;
}

.text-navbar {
  color: $sq-dark-gray;
}

.text-with-shadow {
  text-shadow: 0.5px 0.5px $sq-disabled-gray;
}

.uncertain {
  td.endColumn,
  td.startColumn,
  td.durationColumn {
    color: $sq-darkish-gray;
  }
}

.overlayPanel {
  background-color: rgba($white, 0.93);
  box-shadow: 0 0 2px 2px darken($sq-light-gray, 15%);
}

.loginFooter {
  background-color: rgba($sq-fairly-dark-gray, 0.5);
  color: $sq-dark-gray;
}

#scatterPlotFlipXY {
  border-color: $sq-disabled-gray;
  color: $sq-text-color;

  &:hover {
    border-color: darken($sq-dark-gray, 15%);
  }
}

sq-threshold-metric {
  .colorSwatch {
    border: 1px solid $sq-disabled-gray;
  }
}

.toolOverviewCard {
  &:hover, &:focus {
    background-color: $sq-light-gray;
  }
}

.dateTimeEntryField {
  background-color: $white;
  border: solid 1px $sq-darker-gray;
}

.multiSelectTwoLine {
  .rbt-token {
    color: $sq-text-color;
    background-color: $sq-darker-gray;
    border-color: #cccccc;
  }

  .rbt-input {
    border-color: $sq-darker-gray;
  }

  .dropdown-item {
    &:hover {
      color: $sq-text-color !important;
      background-color: $sq-light-gray !important;
    }
  }

  .form-control {
    border: solid 1px #cccccc;
  }
}

.multiSelectLarge {
  .rbt-token {
    color: $sq-text-color;
    background-color: $sq-darker-gray;
    border-color: #cccccc;
  }

  .rbt-input {
    border-color: $sq-darker-gray;
  }

  .dropdown-item {
    &:hover {
      color: $sq-text-color !important;
      background-color: $sq-light-gray !important;
    }
  }

  .form-control {
    border: solid 1px #cccccc;
  }
}

.datasourcesFilterCard {
  border: $sq-dark-gray solid 1px;
  background-color: $white;
}

.datasourceConnectionCard {
  background-color: $white;
}

.investigateRangeSelector {
  background-color: $white;
  border-top: solid 1px $sq-darker-gray;
}

[content-editable]:hover, [content-editable][contenteditable="true"] {
  color: $black !important;
  background-color: $sq-overlay-gray !important;
}

.investigateRangeSelector, .displayRangeSelector {
  sq-date-time-entry, .displayRangeDateTime, .durationTimeEntry {
    .readOnly {
      color: $sq-side-panel-text-color;
    }
  }
}

.displayRangeSelector {
  p {
    color: $sq-dark-primary-analysis;
  }
}

.reportDateModal {
  .displayRangeSelector {
    p {
      color: $sq-link-color-topic;
    }
  }
}

.requestDetailsTitle {
  color: $sq-text-color;
}

.requestDetailsLegendSwatch {
  box-shadow: 0 0 3px darken($sq-overlay-gray, 15%);
}

.folderExplorerRow {
  &:hover {
    background-color: $sq-light-gray;
  }
}

.dropdown-menu > li > a, .dropdown-item {
  &:hover,
  &:focus {
    color: $white !important;
  }
}

.mainHeader {
  .sq-navbar-default {
    .sq-navbar-noninteractable {
      color: $sq-dark-gray;
    }
  }

  .navbarHomeButton {
    color: $white;

    &:active {
      color: $white;
    }
  }
}


header h4, header h5 {
  color: $white;
}

.headerOptionsButton {
  border-left: $sq-light-thin-border;
}

#dataSourcesPanel {
  background-color: $sq-light-gray;

  .flexRowContainer {
    border-bottom: 1px solid $sq-darkest-gray;
  }
}

.mainFooter {
  background-color: $sq-lightest-gray;
  border-top: 1px solid $sq-darkest-gray;
}

.input-div {
  background-color: $white;
}

.btn-transparent {
  &:hover, &:active {
    color: $white !important;
  }
}

.editableText {
  &:hover {
    background: $sq-overlay-gray;
    color: $sq-text-color;
  }
}

.editableTextInput {
  background: $sq-overlay-gray;
  color: $sq-text-color;
}

.tableBuilderHelpText {
  .coloredAnalysis {
    color: $sq-analysis-color;
  }

  .coloredReport {
    color: $sq-icon-over-topic;
  }
}

.bottomPanel {
  .panelBorderRight {
    border-right: 1px solid $sq-lightest-gray;
  }

  .panelBorderLeft {
    border-left: 1px solid $sq-lightest-gray;
  }

  .header {
    color: $sq-text-color;
  }
}

.workbookAcl {
  ul.dropdown-menu {

    li.active > a {
      &, &:hover, &:focus {
        color: $white;
      }
    }
  }
}

.newWorkbookLabel {
  color: $white;
}

.searchNavigation {
  background-color: $backgroundcolor-block;

  .sq-fairly-dark-gray {
    color: darken($backgroundcolor-block, 30%);
  }
}

.screenshotBackground {
  background-color: $white;
}

.workbookSlide {
  &:hover {
    box-shadow: 0 0 6px darken($sq-light-gray, 15%);
  }

  &.text-center:hover {
    box-shadow: none;
  }

  &.active {
    border-left: 8px solid $sq-active-color;
    background: $sq-light-gray;
  }
}

.worksheetViewSelector {
  .icon {
    color: $white;
  }

  .fa-chevron-down {
    color: $sq-dark-gray;
  }
}

.itemRowSelected {
  border-color: $bordercolor-hover !important;
  background-color: $backgroundcolor-hover !important;
}

.itemRow {
  border-bottom: 1px solid $sq-darkest-gray;

  .searchBtn {
    &.fc-pin {
      @extend .sq-text-primary;
    }

    &:hover:not(.fa-spin), &:focus:not(.fa-spin) {
      @extend .sq-text-primary;
    }
  }
}

.disabledItemRow {
  &:hover, &:focus {
    background-color: transparent !important;
    border-bottom: 1px solid $sq-darkest-gray !important;
  }
}

sq-new-workbench-item {
  .cursorPointer:hover {
    background-color: $backgroundcolor-selected;
  }

  .borderTop {
    border-top: 1px solid $sq-disabled-gray;
  }
}

.toolsSearchBar {
  border-bottom: 1px solid $sq-darker-gray;
}

.alert-info {
  background-color: rgba(207, 201, 202, 0.1);
  color: $sq-text-color;
}

.tabOutline {
  border-left: 1px solid $sq-dark-gray;
  border-right: 1px solid $sq-dark-gray;
  border-bottom: 1px solid $sq-dark-gray;
  padding-top: 10px;
}

/******************* Report Editor *******************/
.reportDateModal {
  .displayRangeSelector {

    a {
      color: $black;
    }

    sq-date-time-entry, .displayRangeDateTime, .durationTimeEntry {
      color: $black;
    }

    div > sq-date-time-entry > div,
    div > .displayRangeDateTime > div {
      border: 1px solid $sq-darker-gray;
    }

    div > sq-date-time-entry.readOnly > div,
    div > .displayRangeDateTime.readOnly > div {
      border: 1px solid $sq-darker-gray;
    }

    #displayRangeNavigation > .durationTimeEntry {
      border: 1px solid $sq-darkest-gray;
    }

    #conditionEditor {
      ul.dropdown-menu {
        li.active > a {
          &, &:hover, &:focus {
            color: $white;
          }
        }
      }
    }
  }

  .hover-text-muted-more {
    color: $sq-disabled-gray;
  }

  .card-primary {
    border-color: $sq-overlay-gray;
  }
}

.searchWidget {
  .heading {
    border-bottom: 1.5px solid $sq-darkest-gray;
  }
}

.administration {
  .leftBorder {
    border-left: 1px solid $sq-dark-gray;
  }
}

.selectedFolderExplorerRow {
  background-color: $sq-light-gray;
}

.textPrimaryChartSettings {
  &:hover, &:active {
    &, & > a {
      color: $sq-dark-primary-analysis;
      background-color: $sq-light-gray;
    }
  }
}

.textPrimaryMenuItem {
  &:hover {
    &, & > a {
      color: $white !important;
    }
  }

  &.dropright > a {
    color: $sq-text-color;
  }
}

.dropdown-menu > .active > a {
  &:hover {
    color: $white !important;
  }
}

ul.searchBreadcrumbs {
  li:last-child, .title {
    color: $sq-fairly-dark-gray !important;
  }
}

.rotateDrag {
  transform: rotate(90deg);
}

.browseColumnCollapsed {
  background-color: $sq-backdrop;
  border: 1px solid $sq-darkest-gray;
}

.dividerBarIconWrapper {
  &:hover {
    .dividerBarIconBg {
      i {
        color: white !important
      }
    }
  }
}

.dividerBarIconWrapperRight {
  &:hover {
    .dividerBarIconBg {
      i {
        color: white !important
      }
    }
  }
}

.browseColumnCollapsed {
  &:hover {
    .dividerBarIconBg {
      i {
        color: white !important
      }
    }
  }
}

.dividerBarIconBg {
  border: 1px solid $sq-darkest-gray;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: $white;
}

.browseColumn {
  background-color: $sq-backdrop;
  border-left: 3px solid $sq-darkest-gray;
  border-right: 3px solid $sq-darkest-gray;

  .toolbar {
    background: $sq-darkest-gray;

    .btn-default {
      background: $white;
    }

    .option .btn .active {
      border-color: $sq-active-color;
      color: $sq-darker-gray;
    }
  }

  .toolOverviewCard {

    &:hover, &:focus {
      background-color: $sq-light-gray;

      .toolDescription {
        color: $sq-fairly-dark-gray;
      }
    }
  }

  .nav-tabs {
    background: $sq-darkest-gray;
  }

  .nav-tabs.nav-justified > li {
    background-color: $sq-darker-gray;

    & > a {
      color: $black;
      border-color: darken($sq-dark-gray, 5%);
    }

    &.active {
      & > a {
        &, &:hover, &:focus {
          background-color: $sq-light-gray;
        }
      }
    }

    &:not(.disabled):not(.active) {
      & > a {
        &:hover, &:focus {
          background-color: $sq-overlay-gray;
        }
      }
    }
  }

  .card.card-default {
    color: $sq-text-color;
    background-color: $white;

    hr {
      border-color: $sq-dark-gray;
    }

    .card-header, .card-body {
      background-color: $white;
    }
  }

  .card.card-primary {
    .card-header, .card-body {
      background-color: $white;
    }
  }

  .card-primary {
    border-color: $sq-dark-gray;
  }
}

.toolbar {
  background-color: $white;
  border-bottom: 2px solid $sq-dark-gray;

  .btn-group:not(.dropdown) {
    border-right: 1px solid $sq-dark-gray;
  }
}

.toolbar-sm {
  border-color: darken($sq-dark-gray, 10%);
}

.optionHeader {
  background: $sq-light-gray;
}

.btn-toolbar {
  color: $sq-text-color !important;

  & .active {
    background: darken($sq-light-gray, 5%);
  }
}

.searchTitleInput {
  & input {
    &:focus {
      color: $sq-text-color;
    }
  }
}

.worksheetViewSelector {
  .icon {
    color: $white;
  }

  .fa-chevron-down {
    color: $sq-dark-gray;
  }
}

.table-white {
  background: $white;
}

.fixedHeaderTable {
  th {
    background: $white;
  }
}

/* workbooks */
.workbookSlide {
  box-shadow: 0 0 6px $white;
}

#regionSlider {
  .selecting circle.selected {
    stroke: $red;
  }

  .resize path {
    fill: $sq-darkest-gray;
    stroke: $sq-text-color;
  }

  .axis path, .axis line {
    stroke: $black;
  }

  .brush .extent {
    fill: #797BFC;
  }
}

#formulaEditorToolbar {
  border: 1px solid $sq-overlay-gray;
  box-shadow: 0 1px 3px rgba($black, 0.12), 0 1px 1px 1px rgba($black, 0.16);
}

.input-div {
  border: 1px solid $sq-disabled-gray;
}

.workbenchBtn {
  @extend .sq-btn-theme;
  color: $white;

  &:hover, &:active, &:focus {
    color: $white !important;
  }
}

.form-control {
  outline: 0 !important;
}

.dropdown-menu > .active > a {

  &:hover {
    color: $white !important;
  }
}

.btn-stat {
  background: $white;

  &:hover {
    border: 1px solid $sq-disabled-gray;
    background: $white;
    border-radius: 2px;
  }
}

.btn-stat-divider {
  padding-right: 6px;
  border-right: 1px solid $sq-disabled-gray;
  margin-right: 6px;
}

[content-editable]:hover, [content-editable][contenteditable="true"] {
  background-color: $sq-overlay-gray !important;
}

.modal-body .optionSelected {
  color: $white;
}

.redactionBannerReload {
  color: lighten($sq-text-warning-color, 25%);

  &:hover {
    color: #8a6d3b;
  }
}

.homeScreenRightPanel {
  width: 400px;
  padding: 10px 20px 80px;
  background-color: $sq-light-gray;
}

.homeScreenLeftPanel {
  border-right-color: $sq-dark-gray;
}

.homeScreenNav {
  padding: 10px;
  font-size: 15px;
  margin-bottom: 5px;
  margin-right: 8px;
  background-color: $sq-light-gray;
}

.homeScreenNotification {
  padding: 20px;
  background-color: $white;
  border: 1px solid $sq-dark-gray;
}

.homeScreenNoContent {
  @extend h4;
  color: $sq-darkest-gray;
}

.newBtnWorkbench {
  .cursorPointer:hover {
    background-color: $backgroundcolor-selected;
  }
}

.homeScreenWorksheetContainer {
  &:hover {
    background: $sq-light-gray;
  }
}

.hoverTable > tr:hover {
  background-color: #EEF6FD !important;
}

.newHomeScreenItem {
  &:hover {
    background-color: #EEF6FD;
  }
}

.newHomeScreenItemWrapper {
  .dropdown-menu > a:hover {
    background-color: #EEF6FD !important;
  }
}

.colorGray {
  color: $sq-disabled-gray;
}

.input-group-append {
  .advanced-options {
    @extend .noBorder;
    background-color: $white;
  }

  .search-icon {
    border: none;
    border-left: 1px solid $sq-disabled-gray;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-color: $sq-light-gray;
  }
}

.paginatorWrapper {
  border-top: 1px solid $sq-dark-gray;
}

.pagination > .active > a {
  &:hover, &:focus {
    background-color: $sq-dark-gray;
    color: $text-color;
    border-color: $sq-dark-gray;
  }

  background-color: $sq-dark-gray;
  color: $text-color;
  border-color: $sq-dark-gray;
}

.pagination > li > a, .pagination > li > span {
  color: $text-color;
}

pre.CodeMirror-placeholder {
  color: #777777 !important;
}

.select-item-entry {
  &:hover {
    background-color: $sq-light-gray;
  }
}

.react-select__group-heading, .react-multiple-select__group-heading {
  background-color: $sq-darker-gray;
}

.react-select.error-border__control {
  // Copy of the bootstraps has-error effect on fields see the form-control-validation mixin in bootstrap source
  // do not add the box shadow as that causes odd artifacts
  border-color: $sq-danger-color !important;

  &:focus {
    border-color: darken($sq-danger-color, 10%);
  }
}

.react-multiple-select__multi-value {
  border: solid 1px $sq-dark-gray;

  &:hover {
    background-color: $sq-light-gray !important;
  }
}

.image-select.react-select__option--is-selected {
  background-color: $white !important;
  color: $sq-text-color;

  &:focus, &:active, &:hover {
    background-color: $sq-light-gray !important;
  }

  > i {
    color: $sq-text-color !important;
  }
}

.btn-transparent.sq-text-danger {
  color: $sq-danger-color !important;
}

.dropdown {
  .dropdown-item:not(.disabled) {
    color: $sq-text-color;

    &:active, &:hover, &:focus {
      color: $white !important;
    }
  }
}

.sq-slider::-moz-range-track {
  background-color: $sq-dark-gray;
}

.fakeTableStriped:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.documentBackground {
  background: $sq-light-gray;
}

.documentContent {
  background: transparent;
}

.sq-btn-danger {
  color: $white;
  background-color: $sq-danger-color;
  border-color: $sq-danger-color;

  &:active, &:active:focus, &:active:hover {
    background-color: darken($sq-danger-color, 10%);
  }
}

.sq-btn-theme-light {
  color: $white;
}

/* IE*/
.sq-slider::-ms-fill-upper {
  background-color: $sq-dark-gray;
}

.errorTooltip {
  .arrow::before {
    border-top-color: $sq-danger-color !important;
  }

  .tooltip-inner {
    background-color: $sq-danger-color;
  }
}

.sampleInput {
  background-color: $sq-light-gray;
  border: 1px solid $sq-darkish-gray;
}

.sq-btn-organizer-migration {
  $migration-color: #00899F;
  color: $white;
  border-color: $migration-color;
  background-color: $migration-color;

  &:focus, &:hover {
    border-color: lighten($migration-color, 3%);
    background-color: lighten($migration-color, 3%);
  }

  &:active, &:active:focus, &:active:hover {
    background-color: lighten ($migration-color, 6%);
    border-color: darken($migration-color, 3%);
  }
}

.homeScreenWelcomeTile {
  background-color: $sq-light-gray;
  border: 1px solid $sq-darkish-gray;
}

.tourCarousel {
  .carousel-indicators {
    li:not(.active) {
      background-color: $sq-disabled-gray;
    }
  }
}

.blackout {
  background-color: rgba(0, 0, 0, 0.6);
}

.noCoverInBlackout {
  background-color: inherit;
}

.hoverGray:hover {
  color: $sq-darkish-gray;
}

.formula-border {
  border: 1px solid $sq-darkish-gray;
}

.formulaExample {
  .CodeMirror {
    background-color: $sq-light-gray;
    border: 1px solid $sq-darkish-gray;
  }
}

figure.seeqTableStriped > table > tbody > tr:nth-of-type(even) {
  background: whitesmoke;
}

.CircularProgressbar-trail {
  stroke: $sq-disabled-gray !important;
}

.CircularProgressbar-text {
  fill: $sq-text-color !important;
}

.sqGrayBox {
  @extend .borderGray;
  @extend .sq-bg-light-gray;
}

.tableViewer {
  background: $white;

  &.customOverlay {
    border: 1px solid $sq-dark-gray;
  }

  .header {
    background-color: $sq-link-color-analysis;
    color: $white;
  }
}

.form-control:invalid, .form-control.is-invalid {
  border-color: $sq-danger-color !important;
}

.pagination {
  .page-item.active {
    .page-link {
      &, &:hover, &:focus {
        color: $white !important;
      }
    }
  }
}

.tableRowAccent {
  background-color: $sq-table-highlight !important;
}

.dividerBg {
  background-color: $sq-darkest-gray;
  overflow: hidden;
}

.dividerBorderTopBottom {
  border-top: 1px solid $sq-lightest-gray;
  border-bottom: 1px solid $sq-lightest-gray;
}
